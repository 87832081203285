import React from 'react'
import { NavLink } from 'react-router-dom'

const Footer = () => {
    return (
        <div className='footer'>

            <h6>Copyright © 2024 <a href="">get20testers.com</a></h6>


            <a href="https://sites.google.com/view/20testers/home">  Privacy policy</a>

        </div>
    )
}

export default Footer